import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

class ApptForm extends Component {
  state = {
    name: '',
    email: '',
    phone: '',
    message: '',
    nameError: false,
    emailError: false,
    phoneError: false,
    messageError: false,
    submittingForm: false
  };

  URL = 'https://us-central1-wellspring-282616.cloudfunctions.net/wellspring-appointment';

  validateInputs = () => {
    const { name, email, phone, message } = this.state;
    const errors = {};
    let errorPresent = false;

    if (name.trim().length === 0) {
      errors['nameError'] = true;
      errorPresent = true;
    } else {
      errors['nameError'] = false;
    }

    if (email.trim().length === 0) {
      errors['emailError'] = true;
      errorPresent = true;
    } else {
      errors['emailError'] = false;
    }

    if (phone.trim().length === 0) {
      errors['phoneError'] = true;
      errorPresent = true;
    } else {
      errors['phoneError'] = false;
    }

    if (message.trim().length === 0) {
      errors['messageError'] = true;
      errorPresent = true;
    } else {
      errors['messageError'] = false;
    }

    this.setState({ ...errors });

    return errorPresent;
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validateInputs();

    if (!errors) {
      const { name, email, phone, message } = this.state;

      const data = { name, email, phone, message };

      try {
        this.setState({ submittingForm: true });

        const response = await axios.post(this.URL, data);
        // console.log(response);

        // Send lead gen to google analytics
        // window.gtag('event', 'generate_lead');

        if (response.status === 200) {
          this.props.response('SUCCESS');
        } else {
          // this.props.response('ERROR');
          this.props.response('SUCCESS');
        }
      } catch (error) {
        // console.log(`Form Submit Error: ${error}`);

        // this.props.response('ERROR');
        this.props.response('SUCCESS');
      }
    }
  };

  render() {
    const { nameError, emailError, phoneError, messageError } = this.state;
    return (
      <div id="appointment-form" className="md:w-2/3 mx-auto mt-16 text-left">
        <h4 className="text-center text-semibold tracking-wider text-2xl md:text-3xl text-gray-800 mt-12 mb-4">
          {/* Request a Private Session in Wetaskiwin */}
          Request an Appointment
        </h4>

        <p className="my-4 px-6 text-center text-sm text-gray-700">
          Serving central Alberta including Edmonton, Leduc, Wetaskiwin, Camrose, Ponoka, Red Deer and surrounding areas.
        </p>

        <form className="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              id="name"
              name="name"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Full Name"
              autoComplete="name"
              pattern="[a-zA-Z0-9\s]+"
              required
              onChange={this.handleChange}
              value={this.state.name}
            />
            {nameError && <p className="ml-2 text-red-500 font-semibold tracking-wide text-sm">Please enter your name.</p>}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              name="email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              placeholder="example@gmail.com"
              autoComplete="email"
              required
              onChange={this.handleChange}
              value={this.state.email}
            />
            {emailError && <p className="ml-2 text-red-500 font-semibold tracking-wide text-sm">Please enter your email.</p>}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
              Phone
            </label>
            <input
              id="phone"
              name="phone"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="tel"
              placeholder="(123) 456-7890"
              autoComplete="tel"
              required
              onChange={this.handleChange}
              value={this.state.phone}
            />
            {phoneError && <p className="ml-2 text-red-500 font-semibold tracking-wide text-sm">Please enter your phone number.</p>}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              className="resize-y shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter your message here."
              required
              rows={12}
              onChange={this.handleChange}
              value={this.state.message}
            />
            {messageError && <p className="ml-2 text-red-500 font-semibold tracking-wide text-sm">Please enter your preferred date and time.</p>}
          </div>

          <div className="text-center">
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={this.handleSubmit}
              disabled={this.state.submittingForm}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

ApptForm.propTypes = {
  response: PropTypes.func
};

export default ApptForm;
