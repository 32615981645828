import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ScheduleAppointment from '../components/ScheduleAppointment';

function Appointments() {
  return (
    <Layout>
      <SEO
        keywords={[`Appointments`]}
        title="Appointments"
      />

      <ScheduleAppointment />
    </Layout>
  );
}

export default Appointments;
