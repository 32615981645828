import React, { useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import ApptForm from './ApptForm';

function ScheduleAppointment() {
  const [showMessageForm, setMessageForm] = useState(true);
  const [showMessageFormError, setMessageFormError] = useState(false);
  const [showMessageFormSuccess, setMessageFormSuccess] = useState(false);

  const { assiniboine, bcst, motherAndBaby } = useStaticQuery(graphql`
    query {
      assiniboine: file(relativePath: { eq: "mt-assiniboine.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bcst: file(relativePath: { eq: "bcst.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      motherAndBaby: file(relativePath: { eq: "mother-and-baby.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const showMessageMe = () => {
    setMessageForm(true);
    setMessageFormError(false);
    setMessageFormSuccess(false);
  };

  const handleResponse = (response) => {
    if (response === 'CANCELLED') {
      setMessageForm(false);
    } else if (response === 'ERROR') {
      setMessageForm(false);
      setMessageFormError(true);
    } else if (response === 'SUCCESS') {
      setMessageForm(false);
      setMessageFormSuccess(true);
    }
  };

  return (
    <div className="px-8 md:px-16 lg:max-w-screen-lg mx-auto">
      <h1 className="my-8 md:my-12 lg:my-16 font-semibold text-lg md:text-2xl lg:text-3xl text-gray-800 tracking-wider text-center">
        Appointments
      </h1>

      <Img
        className="my-6 md:my-12"
        fluid={assiniboine.childImageSharp.fluid}
        alt="Mount Assiniboine"
      />

      <div className="mx-6 md:mx-24 mt-12 md:mb-24">
        <blockquote className="pl-4 font-serif leading-loose text-justify border-l-4 border-gray-900">
          Allow nature&apos;s peace to flow into you.
        </blockquote>
        <cite className="block mt-4 text-xs font-bold text-right uppercase">
          - John Muir
        </cite>
      </div>

      <div className="mx-6 md:mx-24 mt-12 md:mb-24 text-center">
{/*         <p className="font-semibold text-md md:text-lg lg:text-xl text-gray-600 tracking-wider"> */}
{/*           AVAILABLE AT THESE LOCATIONS: */}
{/*         </p> */}
{/*  */}
{/*         <br /><br /> */}

        <div>
          <p className="font-semibold text-md md-text-lg lg-text-lg text-gray-600 tracking-wider">
            PRIVATE HOME PRACTICE
          </p>
          <p>
            <OutboundLink
              className="no-underline hover:underline text-sm"
              href="https://goo.gl/maps/zuYVio7mYwriFMb19"
              target="_blank"
              rel="noopener noreferrer"
            >
              Wetaskiwin County, AB T9A 1X2
            </OutboundLink>
          </p>
          <p>
            CALL/TEXT TO BOOK:{" "}
            <a href="tel:+17802162609" className="no-underline hover:underline text-sm">
              780-216-2609
            </a>
          </p>
          <p className="my-3">
            Or request an appointment here:
          </p>
          <p className="my-3">
            <Link
              to="#appointment-form"
              className="text-sm md:text-base bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Request an Appointment
            </Link>
          </p>
        </div>
      </div>

      <div className="my-12">
        <h4 className="font-semibold text-lg md:text-2xl lg:text-3xl text-gray-600 tracking-wider text-center">
          What to Expect
        </h4>

        <div className="mt-12 mb-24">
          <Img
            className="my-6 md:my-12 mx-auto"
            style={{ maxWidth: "700px" }}
            fluid={bcst.childImageSharp.fluid}
            alt="Biodynamic craniosacral therapy session"
          />
        </div>

        <div className="mx-6 md:mx-24 mt-12 md:mb-24">
          <blockquote className="pl-4 font-serif leading-loose text-justify border-l-4 border-gray-900">
            I will not rescue you, for you are not powerless
            <br />
            I will not fix you, for you are not broken
            <br />
            I will not heal you, for I see you in your wholeness
            <br />
            I will walk with you through the darkness, as you remember your light
          </blockquote>
          <cite className="block mt-4 text-xs font-bold text-right uppercase">
            - Sheree Bliss Tilsley
          </cite>
        </div>

        <p className="mt-8">
          During your first session, a written case history will be created &mdash; both to gather information and to understand your experience and expectations. Sessions typically last an hour, although it can vary. You remain fully clothed and will usually lie face up or on your side on a massage table, or you may be seated. Finding a comfortable position for you is important and the practitioner will have props to support the body. The practitioner will make light contact with your body with her hands and will move to areas that are prioritized by your system. As the body functions as a whole, the practitioner may focus on areas other than where your symptoms occur.
        </p>

        <p className="mt-8 mb-24">
          After a session there can be a period of adjustment where some clients report an increase in awareness of their symptoms. It is important to remember that BCST is a facilitation of the body&apos;s self repair intelligence. The hands-on session is to support your system to access information and resources to make deep, intrinsic reorganizations. Some clients report relief after only one or two sessions. For long-standing issues, further sessions may be required to assist the body in a holistic movement towards greater health.
        </p>

        <h4 className="font-semibold text-lg md:text-2xl lg:text-3xl text-gray-600 tracking-wider text-center">
          What to Expect for Infants
        </h4>

        <div className="mt-12 mb-24">
          <Img
            className="my-6 md:my-12 mx-auto"
            style={{ maxWidth: "600px" }}
            fluid={motherAndBaby.childImageSharp.fluid}
            alt="Mother and baby"
          />
        </div>

        <p className="mt-8">
          In an Infant BCST session, moms and babies are acknowledged and treated as one unit with postpartum care of mama being equally important. I meet the baby&apos;s system AND support how mom&apos;s nervous system is coping. This results in nervous system regulation for both which allows for more ease in your overall function together. In session this looks like placing hands on both mom and baby and listening to the priority between the two. This can lead to hands on just baby, hands on just mom, or hands on both together. This can lead to some improvisation in positioning of moms and babies, however comfort is the priority. There is no need to lie still and the little one is held by their their parent while receiving treatment. I encourage moms to do everything and anything that supports their little one&apos;s comfort during the session including moving about/bouncing, playing, even breast/feeding.
        </p>
      </div>

      {!showMessageForm ?
        (
          <button
            className="mt-16 mx-auto block text-gray-100 uppercase px-8 py-4 font-semibold rounded-lg bg-gray-800 hover:bg-gray-700"
            name="send-message"
            onClick={showMessageMe}
          >
            Request an Appointment
          </button>
        )
        :
          <ApptForm response={handleResponse} />
      }
      {showMessageFormError && <p className="mt-2 text-red-600 font-semibold tracking-wide text-lg text-center">Sorry, your request could not be sent. Please <a className="no-underline hover:underline text-blue-700" href="mailto:wellspringcranio@gmail.com">email</a>/<a className="no-underline hover:underline text-blue-700" href="tel:+17802162609">call</a> us or try again later.</p>}
      {showMessageFormSuccess && <p className="mt-2 text-green-800 font-semibold tracking-wide text-lg text-center">Thank you for your request, we will be in contact shortly.</p>}
    </div>
  );
}

export default ScheduleAppointment;
